import uuid from 'uuid';

import {
    DataCollectionStep,
    ConfirmationDictionary,
    DateQuestion,
    DatetimeQuestion,
    EmailStep,
    EndQuestion,
    GoToBlockQuestion,
    HelpProposeQuestion,
    HttpStep,
    NumericalQuestion,
    OpenQuestion,
    QuestionType,
    RedirectStep,
    SetVariableStep,
    SmsStep,
    SpellingQuestion,
    StatementStep,
    WordAnswerType,
    WordQuestion,
    FunctionStep,
} from '../model';

export const defaultConfirmationDictionary = () => ([
    {id: 'true', key: 'YES', synonyms: []},
    {id: 'false', key: 'NO', synonyms: []}
] as ConfirmationDictionary);

export const defaultConfirmationWordDictionary = (messages = {}) => {
    const getMessage = (key: string) => {
        return messages[`survey-creator.confirmationDictionary${key}`];
    }
    return ([
        {id: getMessage('YES'), key: getMessage('YES'), synonyms: [], type: WordAnswerType.DICTIONARY as const},
        {id: getMessage('NO'), key: getMessage('NO'), synonyms: [], type: WordAnswerType.DICTIONARY as const}
    ]);
}

export const defaultConfirmationReactions = (messages = {}) => {
    const getMessage = (key: string) => {
        return messages[`survey-creator.confirmationDictionary${key}`];
    }
    return [
        {type: WordAnswerType.DICTIONARY, goTo: 'continue', id: getMessage('YES') },
        {type: WordAnswerType.DICTIONARY, goTo: 'continue', id: getMessage('NO') },
    ];
}

export const questionFactory = {
    confirmation: (messages = {}): WordQuestion => ({
        type: QuestionType.WORD,
        message: {text: ''},
        repeatMessages: [{text: ''}],
        fromIntentMessage: {text: ''},
        id: uuid.v4(),
        notPreciseAnswer: [],
        reactions: defaultConfirmationReactions(messages),
        goTo: { repeated: 'continue' },
        ignorableIntents: [],
        anyIfMultipleAnswers: false,
        acceptAnyAnswer: false,
        maxRepeatQuestionCount: 2,
        dictionary: defaultConfirmationWordDictionary(messages),
    }),
    numerical: (): NumericalQuestion => ({
        type: QuestionType.NUMERICAL,
        message: {text: ''},
        repeatMessages: [{text: ''}],
        fromIntentMessage: {text: ''},
        id: uuid.v4(),
        ranges: [],
        goTo: {},
        maxRepeatQuestionCount: 2,
        ignorableIntents: [],
        joinNumbers: false,
    }),
    word: (): WordQuestion => ({
        type: QuestionType.WORD,
        message: {text: ''},
        repeatMessages: [{text: ''}],
        fromIntentMessage: {text: ''},
        id: uuid.v4(),
        notPreciseAnswer: [],
        reactions: [],
        dictionary: [],
        goTo: { repeated: 'continue' },
        ignorableIntents: [],
        anyIfMultipleAnswers: false,
        acceptAnyAnswer: false,
        maxRepeatQuestionCount: 2,
    }),
    datetime: (): DatetimeQuestion => ({
        type: QuestionType.DATETIME,
        date: {message: {text: ''}, repeatMessages: [{text: ''}], fromIntentMessage: {text: ''}},
        hour: {message: {text: ''}, repeatMessages: [{text: ''}], fromIntentMessage: {text: ''}},
        id: uuid.v4(),
        goTo: {},
        ignorableIntents: [],
        maxRepeatQuestionCount: 2,
    }),
    date: (): DateQuestion => ({
        type: QuestionType.DATE,
        message: {text: ''},
        id: uuid.v4(),
        repeatMessages: [{text: ''}],
        fromIntentMessage: {text: ''},
        ignorableIntents: [],
        goTo: {},
        validations: {},
        maxRepeatQuestionCount: 2,
    }),
    open: (): OpenQuestion => ({
        type: QuestionType.OPEN,
        message: {text: ''},
        id: uuid.v4(),
        repeatMessages: [{text: ''}],
        fromIntentMessage: {text: ''},
        ignorableIntents: [],
        maxRepeatQuestionCount: 2,
    }),
    spelling: (): SpellingQuestion => ({
        type: QuestionType.SPELLING,
        message: {text: ''},
        id: uuid.v4(),
        repeatMessages: [{text: ''}],
        fromIntentMessage: {text: ''},
        ignorableIntents: [],
        maxRepeatQuestionCount: 2,
    }),
    send_sms: (): SmsStep => ({type: QuestionType.SMS, message: '', id: uuid.v4(), operator: 'nexmo', from: ''}),
    send_email: (): EmailStep => ({type: QuestionType.EMAIL, message: '', id: uuid.v4(), subject: '', to: ''}),
    data_collection: (): DataCollectionStep => ({type: QuestionType.DATA_COLLECTION, id: uuid.v4(), collectedData: [] }),
    http: (): HttpStep => ({type: QuestionType.HTTP, id: uuid.v4(), url: '', method: 'get', mappings: [], headers: []}),
    set_variable: (): SetVariableStep => ({type: QuestionType.SET_VARIABLE, id: uuid.v4(), variable: '', value: ''}),
    statement: (): StatementStep => ({type: QuestionType.STATEMENT, message: {text: ''}, id: uuid.v4()}),
    redirect: (): RedirectStep => ({
        type: QuestionType.REDIRECT, 
        message: {text: ''}, 
        redirectTo: '', 
        redirectType: 'dial',
        id: uuid.v4()}
    ),
    end: (): EndQuestion => ({type: QuestionType.END, message: {text: ''}, id: uuid.v4()}),
    go_to_block: (): GoToBlockQuestion => ({type: QuestionType.GO_TO_BLOCK, nextBlock: undefined, id: uuid.v4()}),
    help_propose: (): HelpProposeQuestion => ({
        type: QuestionType.HELP_PROPOSE,
        message: {text: ''},
        followupMessage: {text: ''},
        helpNeededMessage: {text: ''},
        fromIntentMessage: {text: ''},
        id: uuid.v4(),
        dictionary: defaultConfirmationDictionary()
    }),
    function: (): FunctionStep => ({type: QuestionType.FUNCTION, id: uuid.v4(), functionName: '', functionInputs: [], functionOutputs: []}),
};
