import { Amplify } from 'aws-amplify';
import * as React from 'react';
import 'react-dates/initialize';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { AppWrapper } from './AppWrapper';
import { getStore } from './store';

Amplify.configure({
    Auth: {
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || 'eu-central-1:944e29ff-ce35-4fd1-855c-c4c46a19888c', // REQUIRED - Amazon Cognito Identity Pool ID
        region: process.env.REACT_APP_AWS_REGION || 'eu-central-1', // REQUIRED - Amazon Cognito Region
        userPoolId: process.env.REACT_APP_USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID,
        cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: process.env.REACT_APP_ALLOWED_COOKIE_DOMAINS?.split('|').includes(window.location.hostname)
                ? window.location.hostname
                : (process.env.REACT_APP_COOKIE_DOMAIN || 'localhost'),
            // OPTIONAL - Cookie expiration in days
            expires: process.env.REACT_APP_STAGE === 'dev' ? 0.25 : 7,
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: !!process.env.REACT_APP_COOKIE_DOMAIN,
            sameSite: 'strict'
        },
    }
});

if (!process.env.REACT_APP_SENTRY_DSN) {
    console.warn('Sentry DSN is not set, Sentry will not be initialized');
} else {
    const environment = process.env.NODE_ENV || 'development';
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment,
        integrations: [new BrowserTracing()],

        tracesSampleRate: 0.01
    });
}

const store = getStore();
deleteAllLegacyCookies();

ReactDOM.render(
    <AppWrapper store={store} />,
    document.getElementById('root') as HTMLElement
);

function deleteAllLegacyCookies() {
    const cookies = document.cookie.split(`;`);

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const eqPos = cookie.indexOf(`=`);
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        if (name.startsWith('CognitoIdentityServiceProvider.43qfsnbg8rnk1m6s6cu30b368e')) {
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure; domain=.analytics.talkie.ai;`;
        } else {
            document.cookie = name + `=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; secure; domain=.talkie.ai`;
        }
    }
}
