import React, { useContext } from 'react';
import {
  ExecutableFunctionInputDefinition,
  ExecutableFunctionWidget,
} from '../../model';
import { TextAreaWithVariables } from '../../../components/TextArea/TextAreaWithVariables';
import { SurveyContext, SurveyContextType } from '../../SurveyCreator';
import { Toggle } from '../../../components/Toggle/Toggle';

type TextInputInputWidgetProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};
export const TextInputInputWidget = ({
  value,
  placeholder,
  onChange,
}: TextInputInputWidgetProps) => {
  const { variables } = useContext<SurveyContextType>(SurveyContext);
  return (
    <TextAreaWithVariables
      value={value}
      asInput
      availableVariables={variables.get()}
      onChange={onChange}
      {...(placeholder && { placeholder })}
    />
  );
};

type TextAreaInputWidgetProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};
export const TextAreaInputWidget = ({
  value,
  placeholder,
  onChange,
}: TextAreaInputWidgetProps) => {
  const { variables } = useContext<SurveyContextType>(SurveyContext);
  return (
    <TextAreaWithVariables
      value={value}
      availableVariables={variables.get()}
      onChange={onChange}
      {...(placeholder && { placeholder })}
    />
  );
};

type CheckboxInputWidgetProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const CheckboxInputWidget = ({
  value,
  onChange,
}: CheckboxInputWidgetProps) => {
  return <Toggle on={value} onToggle={() => onChange(!value)} />;
};

type FunctionInputWidgetProps = {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
};

export const renderInputTypeToWidget = (
  widgetType: ExecutableFunctionInputDefinition['render']
): React.FC<FunctionInputWidgetProps> => {
  switch (widgetType) {
    case ExecutableFunctionWidget.TextInput:
      return TextInputInputWidget;
    case ExecutableFunctionWidget.TextArea:
      return TextAreaInputWidget;
    case ExecutableFunctionWidget.Checkbox:
      return CheckboxInputWidget;
    default:
      return null;
  }
};
