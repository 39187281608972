import _ from 'lodash';
import React, {useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import crossImg from '../../images/cross-grey.svg';
import {getNamesDictionary} from '../../selectors';
import {useCurrentBot} from '../../utils/CurrentBot';

import styles from './EventsBar.pcss';
import { ConversationsEventsFilters } from '../reports/useReports';

interface Props {
    readonly events?: ConversationsEventsFilters;

    onChange(newFilters: ConversationsEventsFilters): void;
}

const emptyEvents = {
  information: [],
  recognizedIntents: [],
  results: [],
  surveyQuestions: [],
  technicalInformation: [],
};

export function EventsBar({events, onChange}: Props) {
    const namesDictionary = useSelector(getNamesDictionary);

    const removeFilter = useCallback(
        removedEvent => {
            onChange(
                _.keys(events).reduce(
                    (current, key) => ({
                        ...current,
                        [key]: events[key].filter(value => value !== removedEvent)
                    }),
                    emptyEvents
                )
            );
        },
        [events, onChange]
    );

    const removeAll = useCallback(() => {
        onChange(
            _.keys(events).reduce(
                (current, key) => ({
                    ...current,
                    [key]: []
                }),
                emptyEvents
            )
        );
    }, [events, onChange]);

    const selectedValues = _.flatten(_.values(events));
    const bot = useCurrentBot();
    const intl = useIntl();

    const getLabelFor = (key: string) => {
        return bot.type === 'survey'
            ? intl.messages[`conversations.filter.${key}`] as string || namesDictionary[key].label
            : namesDictionary[key].label;
    };

    return (
        <div className={styles.filters}>
            {selectedValues.length > 0 && (
                <>
                    {selectedValues.map(key => (
                        <div key={key} onClick={() => removeFilter(key)}>
                            {getLabelFor(key)}
                            <img alt={'cross'} className={styles.cross} src={crossImg}/>
                        </div>
                    ))}
                    <div className={styles.clearAll} onClick={removeAll}>
                        <FormattedMessage id="conversations.filters.events.clear"/>
                    </div>
                </>
            )}
        </div>
    );
}
